html,
body,
#root,
.Koors,
.Login {
  height: 100%;
  font-family: 'Fredoka', sans-serif;
}

.Login {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.login-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  margin-top: -75px;
}