.App {
  text-align: center;
  justify-content: center;
  display: flex;
}

.container {
  display: flex;
  width: 320px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.sub-title {
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  text-align: left;
}

.recettes {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.recette {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  max-width: 130px;
  width: 130px;
  height: 45px;
  border-radius: 8px;
  margin: 10px 10px 10px 10px;
  padding: 15px 5px 15px 5px;
  background-color: #f28b82;
  cursor: pointer;
  user-select: none;
}

.recette:hover {
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
}

.recette-nb-personnes {
  opacity: 0.7;
  position: absolute;
  bottom: 2px;
  left: 2px;
  font-size: 15px;
  font-weight: bold;
}


.recette-link {
  opacity: 0.5;
  position: absolute;
  bottom: 2px;
  right: 2px;
  font-size: 15px;
}

.recette-link:hover {
  opacity: 1;
}

.recette-link>a {
  text-decoration: none;
  /* no underline */
}

.recette-delete {
  position: absolute;
  top: 2px;
  right: 2px;
}

.singleItem {
  background-color: #cbf0f8;
}

.dessert {
  background-color: #feefc3;
}


.flemme {
  background-color: #fdd5c3;
}

.recette-title {
  display: flex;
}

.list {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
  align-items: center;
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: #eee solid 1px;

  user-select: none;
}

.itemName {
  text-align: left;
}

.itemChecked {}

.itemQuantityControls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70px;
}

.itemButton {
  cursor: pointer;
  color: black;
}

.noItem {
  color: lightgray;
}

.itemButton:hover {
  color: gray;
}

.itemQuantity {
  min-width: 30px;
}

.itemNameField {
  width: 160px;
  margin-right: 20px;
}

.actionButton {
  min-width: 65px;
  margin: 5px 10px 5px 10px;
  padding: 10px 15px 10px 15px;
  border: none;
  background-color: #4CAF50;
  color: white;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border-radius: 4px;
}

.actionButton:hover {
  background-color: #4CAFCC;
}

.actionButton:disabled {
  opacity: 0.7;
  cursor: default;
  background-color: #4CAF50;
}

.actionButton.light {
  color: #4CAF50;
  background-color: #fff;
  border: solid 1px #4CAF50;
}

.actionButton.light:hover {
  background-color: #4CAFCC;
  color: #fff;
  border: solid 1px #4CAFCC;
}

@media screen and (min-width: 900px) {
  .container {
    width: 900px
  }

  .recette {
    max-width: 250px;
    width: 250px;
    margin: 10px 20px 10px 20px;
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .container {
    width: 600px
  }

  .recette {
    max-width: 160px;
    width: 160px;
    margin: 10px 10px 10px 10px;
  }
}

.modal-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.modal {
  padding: 15px;
  min-width: 100px;
  max-width: 80%;
  min-height: 100px;
  max-height: 80%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  overflow-y: auto;
}

.modal-title {
  font-size: 20px;
  margin-bottom: 30px;
}